import { Player } from '@lottiefiles/react-lottie-player';
import { useEffect, useMemo, useState } from "react";
import { Direction } from 'app/design/LogoOne';
import { MotiView } from 'moti';

export default ({ size, direction, done, animate, dark }: { size: number, direction: Direction, done?: () => void, animate: boolean, dark?: boolean }) => {
  const [attempts, setAttemps] = useState(0);

  const rotation = useMemo(() => {
    switch (direction) {
      case "sw":
        return -90;
      case "se":
        return 180;
      case "ne":
        return 90;
      case "nw":
        return 0;
    }
  }, [direction]);

  useEffect(() => {
    let timeout: any;
    if (done) {
      if (animate) {
        // Safety hatch
        timeout = setTimeout(() => {
          done();
        }, 300);
      } else {
        done();
      }
    }

    return () => clearTimeout(timeout);
  }, []);

  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    let timeout: any;
    if (animate && playing) {
      setTimeout(() => {
        if (done) done();
      }, 200);
    }

    return () => clearTimeout(timeout);
  }, [playing]);

  return attempts > 10 ? null : (
    <MotiView
      style={{
        width: size,
        height: size,
        backgroundColor: "transparent",
      }}
      transition={{
        type: "timing",
        duration: 500,
      }}
      animate={{
        rotate: `${rotation}deg`,
      }}
    >
      <Player
        key={attempts}
        autoplay
        loop={false}
        keepLastFrame
        speed={animate ? 2 : 1000}
        onEvent={e => {
          if (e === "play") {
            setPlaying(true);
          }
          if (e === "error") {
            setAttemps(attempts + 1);
          }
        }}
        src={dark ? "/lottie/oase_red.json" : "/lottie/oase.json"}
        style={{
          backgroundColor: "transparent",
          height: `${size}px`,
          width: `${size}px`,
        }}
      />
    </MotiView>
  );
};
