import { Player } from '@lottiefiles/react-lottie-player';
import { Text } from 'app/native';
import { MotiView } from 'moti';

export default ({ done }: { done: () => void }) => {
  return (
    <>
      <Player
        autoplay
        keepLastFrame
        onEvent={e => {
          if (e === "complete") {
            done();
          }
        }}
        src="/lottie/name_big_splash.json"
        style={{ height: '170px', width: '270px' }}
      />
      <MotiView
        from={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          delay: 200,
          type: 'timing',
          duration: 600,
        }}
      >
        <Text className="text-white font-t-light text-2xl text-center px-10">
          Chat-app'en der godt<br />vil have vi mødes
        </Text>
      </MotiView>
    </>
  );
};
