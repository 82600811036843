import SplashLogo from 'app/components/SplashLogo';
import { useRef, useEffect, useState, useCallback, memo } from "react";
import { StatusBar } from 'react-native';
import { AnimatePresence, MotiView } from 'moti';
import {
  StyleSheet,
  View,
  Platform,
  useWindowDimensions,
} from "react-native";
import SplashFader from './SplashFader';

export default memo(({ children, runningCallback, doneCallback, appIsReady, run }: { children: React.ReactElement, runningCallback?: () => void, appIsReady: boolean, run: boolean, doneCallback?: () => void }) => {
  const [isFullAnimationComplete, setIsFullAnimationComplete] = useState(!run);
  const [logoAnimationDone, setLogoAnimationDone] = useState(!run);
  const logoAnimationDoneRef = useRef(logoAnimationDone);
  const { height } = useWindowDimensions();

  logoAnimationDoneRef.current = logoAnimationDone;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!logoAnimationDoneRef.current) {
        console.log("SAFETY HATCH setLogoAnimationDone(true)");
        setIsFullAnimationComplete(true)
      }
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  const onLayout = useCallback(() => {
    if (runningCallback) {
      runningCallback();
    }
  }, [runningCallback]);

  useEffect(() => {
    if (isFullAnimationComplete && run) {
      if (doneCallback) {
        doneCallback();
      }
      StatusBar.setBarStyle("dark-content");
    } else {
      StatusBar.setBarStyle("light-content");
    }
    if (Platform.OS == "android") {
      StatusBar.setBackgroundColor("#00000000");
      StatusBar.setTranslucent(true);
    }
  }, [isFullAnimationComplete, doneCallback]);

  const animationDoneCallback = useCallback(() => {
    setLogoAnimationDone(true);
  }, [setLogoAnimationDone]);

  const faderCallback = useCallback(() => {
    // console.log("faderCallback");
    setIsFullAnimationComplete(true)
  }, [setIsFullAnimationComplete]);

  // console.log(`AnimatedSplashScreen: ${JSON.stringify({ appIsReady, doneCallback }, null, 2)}`);
  return (
    <View style={{ flex: 1 }}>
      {children}
      <AnimatePresence>
        {isFullAnimationComplete ? null : (
          <MotiView
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: "transparent",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                height: Platform.OS == "web" ? height : undefined,
                overflow: 'hidden',
                zIndex: 1000,
              },
            ]}
            from={{
              opacity: 1,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            exitTransition={{
              type: "timing",
              duration: 500,
            }}
          >
            <SplashFader runAnimation={(appIsReady && logoAnimationDone)} done={faderCallback} onLayout={onLayout} />
            {appIsReady ? <SplashLogo done={animationDoneCallback} /> : null}
          </MotiView>
        )}
      </AnimatePresence>
    </View>
  );
});

