import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useCallback, memo } from "react";
import { LinearGradient } from 'expo-linear-gradient';
import { MotiView, useAnimationState } from 'moti';

import {
  useWindowDimensions,
} from "react-native";

export default memo(({ runAnimation, done, onLayout }: { runAnimation: boolean, done: () => void, onLayout: () => void }) => {
  const { height } = useWindowDimensions();

  const animationState = useAnimationState({
    from: {
      height: height * 2,
    },
    go: {
      height: height,
    }
  })

  useEffect(() => {
    if(runAnimation) {
      animationState.transitionTo('go');
      setTimeout(done, 700);
    }
  }, [runAnimation]);


  // console.log(`AnimatedSplashScreen: ${JSON.stringify({ isSplashAnimationComplete, logoAnimationDone, appIsReady }, null, 2)}`);
  return (
    <MotiView
      state={animationState}
      transition={{
        type: "timing",
        duration: 800,
        delay: 0,
      }}
      style={{
        top: 0,
        position: 'absolute',
        width: '100%',
      }}
    >
      <LinearGradient
        onLayout={onLayout}
        // Background Linear Gradient
        colors={["#4AC4A9", "#A0C4B0", "#F6C4B7"]}
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </MotiView>
  );
});

