import { memo, useEffect, useState } from 'react';
import { Text, TouchableOpacity, View } from 'app/native'
import { ViewProps } from 'react-native'
import { Direction } from 'app/design/LogoOne'
import useTranslation from 'app/hooks/useTranslation'
import classnames from 'classnames';
import OaseBubble from './OaseBubble';
import { MotiView } from 'moti';

export default memo(({ direction, step, onPress, size, delay, ...props }: ViewProps & { onPress?: () => void, direction: Direction, delay?: number, step: string, size: number }) => {
  const { t } = useTranslation();
  const text = t(`oase.onboarding.${step}`);
  const [showText, setShowText] = useState(false);
  // If direction is n, e, w or s then it is tilted:
  const tilted = direction === "n" || direction === "e" || direction === "w" || direction === "s";
  const [animate, setAnimate] = useState(false);
  const onboardingTextClasses = classnames("text-white tracking-normal p-0 text-center mx-3 font-t-bold", {
    "mx-3": tilted,
    "mx-6": !tilted,
  });

  useEffect(() => {
    let timeout: any;
    if (delay) {
      setTimeout(() => {
        timeout = setAnimate(true);
      }, delay);
    } else {
      setAnimate(true);
    }

    return () => clearTimeout(timeout);
  }, []);

  return !animate ? null : (
    <View {...props} style={[props.style, {
      width: size,
      height: size,
    }]}>
      <View style={{
      }} className="absolute top-0 left-0">
        <OaseBubble dark done={() => setShowText(true)} direction={direction} size={size} animate />
      </View>
      {showText ? <MotiView
        from={{
          opacity: 0,
          scale: 0.9,
        }}
        animate={{
          opacity: 1,
          scale: 1,
        }}
        transition={{
          type: "timing",
          duration: 200,
        }}
        style={{
          flex: 1
        }}
      >
        {onPress ? (
          <TouchableOpacity onPress={onPress} className="h-full w-full flex items-center justify-center">
            <Text className={onboardingTextClasses}>{text}</Text>
          </TouchableOpacity>
        ) : (
          <View className="h-full w-full flex items-center justify-center">
            <Text className={onboardingTextClasses}>{text}</Text>
          </View>
        )}
      </MotiView> : null}
    </View>
  );
});
