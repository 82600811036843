import { useEffect } from "react";
import SplashScreenAnimation from 'app/components/SplashScreenAnimation';

export default ({
  children,
  ready,
  run,
}: {
  children: React.ReactElement,
  ready: boolean,
  run: boolean | null,
}) => {

  return run ? (
    <SplashScreenAnimation appIsReady={ready} run={run}>
      <>
        {ready && children}
      </>
    </SplashScreenAnimation>
  ) : ready ? <>{children}</> : null;
};
