import { HubScreen } from 'app/features/hub/screen'
import { MainLayout } from 'app/navigation/web/Main'

const Index = () => {
  return (
    <MainLayout>
      <HubScreen />
    </MainLayout>
  );
}

export default Index;
