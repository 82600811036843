import { useState, useCallback, memo, useEffect, useMemo, useRef, useContext } from 'react'
import { Text, View, ScrollView, Image, ButtonLinkOpacity } from 'app/native'
import { Platform, useWindowDimensions } from 'react-native'
import { useSafeArea } from 'app/provider/safe-area/use-safe-area';
import useTranslation from 'app/hooks/useTranslation'
import useProjection from 'app/hooks/useProjection'
import usePerson from 'app/hooks/usePerson'
import LoginOrOpenDrawer from './LoginOrOpenDrawer'
import Landing from './landing'
import Create from './create'
import OaseCard from './OaseCard'
import { Direction } from 'app/design/LogoOne'
import AnimatedSplashScreen from 'app/components/AnimatedSplashScreen';
import Loading from 'app/components/Loading';
import { LinearGradient } from 'expo-linear-gradient';
import classnames from 'classnames';
import { MotiView, AnimatePresence } from 'moti';
import Haptics from 'app/lib/haptics';
import Footer from './Footer';
import { LogoFour } from 'app/design/LogoFour';
import usePersonOnboarding from 'app/hooks/usePersonOnboarding';
import AppStoreIcons from 'app/components/AppStoreIcons';
import { AddToCalendar } from './AddToCalendar';
import SplashContext from 'app/contexts/splash'
import { GiveFeedback } from 'app/components/GiveFeedback';

export type SortMap = {
  [key: string]: number;
};

export type OaseFromServer = {
  oase_id: string;
  participants: any[];
  oase_name: string;
  family: boolean;
}

export function HubScreenComp() {
  const { t } = useTranslation()
  const user = usePerson();
  const [projection] = useProjection(`evolving:hub:screen:${user?.id}`, {priority: true});
  const safeArea = useSafeArea();
  const [splashAnimationDone, setAnimationDone] = useState(Platform.OS == "web");
  const [limitCollectionForFirstRender, setLimitCollectionForFirstRender] = useState(Platform.OS != "web");
  const [shouldAnimateOases, setShouldAnimateOases] = useState(true);
  const [nextStep] = usePersonOnboarding();
  const promoteInstallApp = Platform.OS == "web" && nextStep === "installApp";
  const { width } = useWindowDimensions();

  const { afterSplash } = useContext(SplashContext);
  useEffect(() => {
    if (afterSplash) {
      afterSplash.then(() => {
        setAnimationDone(true);
        setTimeout(() => {
          setLimitCollectionForFirstRender(false);
        }, 2000);
      });
    }
  }, [afterSplash, setAnimationDone]);

  const collection: OaseFromServer[] = useMemo(() => {
    return projection?.collection || [];
  }, [projection?.collection]);

  const sortMap = useMemo(() => {
    const result: SortMap = {};
    if (!collection) return result;

    collection.forEach((oase) => {
      const activities = oase.participants.map((participant: any) => Date.parse(participant.last_activity_at));
      const max = Math.max(...activities);
      result[oase.oase_id] = max;
    });

    return result;
  }, [collection]);

  const sortedCollection = useMemo(() => {
    if (!collection) return [];
    if (!sortMap) return [];

    return [...collection].sort((a: any, b: any) => {
      return (sortMap[b.oase_id] || 0) - (sortMap[a.oase_id] || 0);
    });
  }, [collection, sortMap]);

  const collectionToRender = useMemo(() => {
    if (!sortedCollection) return [];
    if (!splashAnimationDone) return [];

    if (limitCollectionForFirstRender) {
      return sortedCollection.slice(0, 12);
    }
    return sortedCollection;
  }, [splashAnimationDone, limitCollectionForFirstRender, sortedCollection]);

  useEffect(() => {
    // Disable animation after initial splash
    // This allows oases to move around the screen without having to animate
    // each time.
    let timeout: any;
    if (!splashAnimationDone) {
      timeout = setTimeout(() => {
        // This is what does it in the app.
        // setAnimationDone(true);
        setTimeout(() => {
          setShouldAnimateOases(false);
        }, 3000);
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [splashAnimationDone, setAnimationDone]);

  useEffect(() => {
    // Allow oases to animate in when logging in
    let timeout: any;
    if (!shouldAnimateOases) {
      setShouldAnimateOases(true);
      timeout = setTimeout(() => {
        setShouldAnimateOases(false);
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [user]);

  const translateWidth = useMemo((): ("default" | "sm" | "md") => {
    if (width < 640) {
      return "default";
    } else if (width < 768) {
      return "sm";
    } else {
      return "md";
    }
  }, [width]);

  const oaseCount = useMemo(() => projection?.collection?.length || 0, [projection?.collection?.length]);
  const placeholders = useMemo(() => Array.from({ length: 4 - oaseCount }, (_, i) => oaseCount + i), [oaseCount]);
  const allCount = useMemo(() => oaseCount + placeholders.length, [oaseCount, placeholders.length]);
  const oasesPerRow = useMemo(() => {
    if (allCount < 5) return 2;

    switch (translateWidth) {
      case "sm":
        return 3;
      case "md":
        if (allCount <= 9) return 3;
        return 4;
      default:
        return 2;
    }
  }, [translateWidth, allCount]);

  const fillers = useMemo(() => {
    // Depending on how many oases are in a row, we need to add fillers 
    // to make the grid look nice.
    const countInLastRow = allCount - (Math.floor(allCount / oasesPerRow) * oasesPerRow);
    if (countInLastRow == 0) return [];
    return Array.from({ length: oasesPerRow - countInLastRow }, (_, i) => allCount + i)
  }, [oasesPerRow, allCount]);

  const hasOaseOverview = useMemo(() => oaseCount > 0 || Platform.OS != "web", [oaseCount]);
  const logoText = useMemo(() => hasOaseOverview ? t("hub.header") : "Oase", [hasOaseOverview, t]);
  const logoClasses = useMemo(() => classnames("text-brand-black-900 text-2xl font-b-semi", {
    "text-2xl tracking-wider web:tracking-wide": true,
  }), []);

  const hasOnboarding = useMemo(() => oaseCount < 3, [oaseCount]);

  const oaseSize = useMemo(() => {
    const result = Math.min((width / oasesPerRow) - 10, 180);
    return result;
  }, [width, oasesPerRow]);

  const oaseContainerClasses = useMemo(() => classnames("pt-3 flex flex-row flex-wrap items-center justify-center gap-0.5", {
    "max-w-lg": oasesPerRow == 2,
    "max-w-3xl": oasesPerRow == 3,
    "max-w-4xl": oasesPerRow == 4,
  }), [oasesPerRow]);

  const cardClasses = useMemo(() => "m-0", []);

  const directions = useMemo((): Direction[] => {
    switch (oasesPerRow) {
      case 3:
        return ["nw", "se", "ne", "ne", "sw", "se", "sw", "ne", "sw", "se", "nw", "sw"];
      case 4:
        return ["nw", "se", "nw", "se", "ne", "sw", "ne", "sw", "sw", "ne", "sw", "ne", "se", "nw", "se", "nw"];
      default:
        return ["nw", "se", "ne", "sw", "sw", "ne", "se", "nw"];
    };
  }, [oasesPerRow]);

  const direction = useCallback((index: number): Direction => {
    return directions[index % directions.length] as Direction;
  }, [directions]);

  const readyForSplashAnimation = useMemo(() => !!projection && !!projection.collection, [projection]);
  const runSplash = useMemo(() => (Platform.OS != "web" ? false : projection?.collection ? projection?.collection?.length == 0 : null), [projection?.collection?.length]);

  return (
    <AnimatedSplashScreen
      // Here to run splash on web
      run={!!runSplash}
      ready={readyForSplashAnimation}
    >
      <View className="flex-1 bg-gradient-to-b min-h-screen from-[#D6E3DF] to-[#EEE5DF]">
        {(Platform.OS != "web") ? <LinearGradient
          // Background Linear Gradient
          colors={["#D6E3DF", "#EEE5DF"]}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
          }}
        /> : null}
        <ScrollView
          scrollEventThrottle={16}
          keyboardShouldPersistTaps="handled"
          style={{
            paddingTop: safeArea.top,
            paddingLeft: safeArea.left,
            paddingRight: safeArea.right,
            paddingBottom: safeArea.bottom
          }}
          className="" >
          <View className="relative z-10 w-full">
            <MotiView
              from={{
                opacity: 0,
              }}
              animate={{
                opacity: splashAnimationDone ? 1 : 0,
              }}
              transition={{
                type: "timing",
                duration: 700,
                delay: 1000
              }}
              style={{
                width: "100%",
              }}
            >
              <View className="relative z-10 flex w-full flex-row pt-4 mb-3 items-center justify-center">
                <View className="ml-4 absolute left-0 top-0">
                  <LoginOrOpenDrawer />
                </View>
                <View className="flex flex-col items-center">
                  {hasOaseOverview ? (
                    <Text className={logoClasses}>{logoText}</Text>
                  ) : (
                    <View className="hidden sm:flex flex-row items-center justify-start">
                      <View className="w-8 h-8 mr-2">
                        <LogoFour size="small" fill="fill-brand-black-900" />
                      </View>
                      <Text className="text-3xl text-brand-black-900 tracking-wider font-b-semi text-center">Oase</Text>
                    </View>
                  )}
                </View>
                <View className="absolute z-20 right-0 top-0">
                  <Create />
                </View>
              </View>
            </MotiView>
          </View>
          <View className="flex items-center justify-center">
            {hasOaseOverview ? (
              <>
                {promoteInstallApp ? (
                  <View className="mb-4">
                    <AppStoreIcons />
                    <View className="flex flex-row space-x-2 items-center justify-center -mt-2">
                      <Text className="text-center font-b-bold rotate-180">⤵</Text>
                      <Text className="text-center text-base font-b-regular mt-3">{t("hub.we_have_an_app")}</Text>
                      <Text className="text-center font-b-bold ">⤴</Text>
                    </View>
                  </View>
                ) : null}
                <View className={oaseContainerClasses}>
                  {collectionToRender.map((oase: any, index: number) => (
                    <View
                      key={`${oase?.oase_id}`}
                      className={`${cardClasses}`}
                    >
                      <OaseCard size={oaseSize} index={index} animate={shouldAnimateOases} show={splashAnimationDone} direction={direction(index)} data={oase} />
                    </View>
                  ))}
                  {placeholders.map((index) => (
                    <View
                      key={`${index}-${direction(index)}-placeholder`}
                      className={`${cardClasses}`}
                    >
                      <OaseCard size={oaseSize} index={index} animate={shouldAnimateOases} show={splashAnimationDone} direction={direction(index)} placeholder />
                    </View>
                  ))}
                  {fillers.map((index, _) => (
                    <View
                      key={`${index}-${direction(index)}-filler`}
                      className={`${cardClasses} opacity-0`}
                    >
                      <OaseCard size={oaseSize} index={index} animate={shouldAnimateOases} show={splashAnimationDone} direction={direction(index)} placeholder />
                    </View>
                  ))}
                </View>
              </>
            ) : null}
            <AnimatePresence>
              {splashAnimationDone ? <MotiView
                from={{
                  opacity: 0,
                }}
                exit={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                transition={{
                  type: "timing",
                  duration: 700,
                  delay: 200
                }}
              >
                {hasOnboarding ? (
                  <>
                    <Landing />
                  </>
                ) : (
                  <View className="web:h-20 h-96">

                  </View>
                )}
                {Platform.OS == "web" ? (
                  <View className="flex flex-row items-center justify-center mt-0 -ml-1">
                    <ButtonLinkOpacity href="https://play.google.com/store/apps/details?id=app.oase">
                      <Image alt='Get it on Google Play' width={646} height={250} className="w-44" contentFit="contain" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                    </ButtonLinkOpacity>
                    <ButtonLinkOpacity href="https://apps.apple.com/us/app/oase/id6446152196?platform=iphone">
                      <Image alt='Get it on App Store' width={120} height={40} className="w-36" contentFit="contain" src='/apple-app-store.svg' />
                    </ButtonLinkOpacity>
                  </View>
                ) : null}
                <View className="mx-auto">
                  <GiveFeedback />
                </View>
                <View className="mx-auto">
                  <AddToCalendar />
                </View>
                <Footer />
              </MotiView> : null}
            </AnimatePresence>
          </View>
        </ScrollView >
      </View>
    </AnimatedSplashScreen>
  );
}

export const HubScreen = memo(HubScreenComp);
