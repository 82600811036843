import { memo, useEffect, useMemo, useState } from 'react'
import { Pressable } from "app/native";
import { useRouter } from 'solito/router'
import usePersonOnboarding from 'app/hooks/usePersonOnboarding'
import classnames from 'classnames';
import { SuperEllipse } from 'app/design/SuperEllipse'
import classNames from 'classnames';
import { FontAwesomeIcon } from 'app/icon';
import Onboarding from 'app/components/Onboarding'
import { get, set } from 'app/store/secure-store'
import usePerson from 'app/hooks/usePerson';

export default memo(() => {
  const { push } = useRouter()
  const person = usePerson();
  const [sawCreateOasePage, setSawCreateOasePage] = useState<boolean>();

  const storageKey = useMemo(() => person?.id ? `saw-create-oase-page-${person.id}` : null, [person?.id]);
  useEffect(() => {
    if (!storageKey) return;
    get(storageKey).then((value) => {
      if(!value) {
        setSawCreateOasePage(false);
        return;
      }

      const lastClick = new Date(parseInt(value));
      const oneWeek = 1000 * 60 * 60 * 24 * 7;
      const timeSinceLastClick = Date.now() - lastClick.getTime();

      if (timeSinceLastClick > oneWeek) {
        // It's been more than a week since they've seen the create oase page.
        // Let's pretend they didn't see it.
        setSawCreateOasePage(false);
      } else {
        // They saw the page within the last week.
        setSawCreateOasePage(true);
      }

    });
  }, [storageKey]);

  const [nextStep] = usePersonOnboarding();
  const weAreNext = nextStep === 'createOase';

  return <>
    <Pressable
      key={"new"}
      className={classnames("flex flex-col space-x-2 items-end justify-center p-4", {
        "scale-150": weAreNext,
      })}
      onPress={() => {
        if(storageKey) set(storageKey, Date.now().toString());
        setSawCreateOasePage(true);
        push("/new")
      }}
    >
      <SuperEllipse className={classNames("w-8 h-8 flex items-center justify-center", {
      })} fill={weAreNext ? "fill-brand-primary" : "fill-transparent"} stroke={weAreNext ? "stroke-brand-primary" : "stroke-brand-black-700 stroke-[10]"}>
        <FontAwesomeIcon icon={["fas", "plus"]} className={`outline-none w-4 h-4 ${weAreNext ? "text-white" : "text-brand-black-700"}`} />
      </SuperEllipse>
      {weAreNext && sawCreateOasePage === false ? (
        <Onboarding delay={10000} className="absolute right-12 -bottom-28 z-20" size={130} direction={"ne"} step="createOase" />
      ) : null}
    </Pressable>
  </>
});
