import { FontAwesomeIcon } from "app/icon";
import { View, Text, Link } from "app/native";
import useTranslation from 'app/hooks/useTranslation';
import { LogoOne } from "app/design/LogoOne";

export function GiveFeedback() {
  const { t } = useTranslation();

  return (
    <View className="w-screen px-6 py-24 sm:py-32 lg:px-8">
      <View className="mx-auto max-w-2xl">
        <Text className="text-3xl font-t-bold tracking-tight text-gray-900 sm:text-4xl text-center">{t("giveFeedback.title")}</Text>
        <Text className="mt-2 text-lg leading-8 font-t-regular text-gray-600">
          {t("giveFeedback.subtitle")}
        </Text>
      </View>
      <View className="mx-auto mt-20 max-w-lg space-y-16">
        <View className="flex w-full flex-row gap-x-6 ">
          <View className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-brand-primary">
            <FontAwesomeIcon icon={["fas", "envelope"]} className="h-6 w-6 text-white" />
          </View>
          <View className="shrink">
            <Text className="text-base font-t-semibold leading-7 text-gray-900">
              {t("giveFeedback.email.title")}
            </Text>
            <Text className="mt-2 leading-7 font-t-regular text-gray-600">
              {t("giveFeedback.email.description")}
            </Text>
            <Text className="mt-4">
              <Link href="mailto:feedback@oase.app">
                <Text className="text-sm font-t-semibold leading-6 text-brand-primary">
                  {t("giveFeedback.email.cta")}
                </Text>
              </Link>
            </Text>
          </View>
        </View>
        <View className="flex w-full flex-row gap-x-6">
          <View className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-brand-primary">
            <LogoOne size={18} fill="fill-white" />
          </View>
          <View className="shrink">
            <Text className="text-base font-t-semibold leading-7 text-gray-900">
              {t("giveFeedback.oase.title")}
            </Text>
            <Text className="mt-2 font-t-regular leading-7 text-gray-600">
              {t("giveFeedback.oase.description")}
            </Text>
            <Text className="mt-4">
              <Link href="/oase/cdec9a6b-a2da-4bbc-a3c5-110b7ee23c58/join/08a5c210-07f5-487d-bd74-1db127850de8">
                <Text className="text-sm font-t-semibold leading-6 text-brand-primary">
                  {t("giveFeedback.oase.cta")}
                </Text>
              </Link>
            </Text>
          </View>
        </View>

      </View>
    </View>
  )
}
