import { memo, useState, useEffect, useMemo, useCallback } from 'react'
import { Text, View, FullText } from 'app/native'
import OaseBubble from 'app/components/OaseBubble'
import useTranslation from 'app/hooks/useTranslation'
import useNotification from 'app/hooks/useNotification'
import { relativeWithToday } from 'app/hooks/useRelativeTime'
import { iconAndName } from 'app/lib/iconAndName'
import { SuperEllipse } from 'app/design/SuperEllipse'
import { ParticipantAvatar, Participant } from 'app/features/oase/ParticipantAvatar'
import { MotiView, useDynamicAnimation } from 'moti'
import { Platform } from 'react-native'
import { useRouter } from 'solito/router';
import { GestureDetector, Gesture } from 'react-native-gesture-handler';
import { FontAwesomeIcon } from 'app/icon'
import classNames from 'classnames'
import { themeColor } from 'app/design/OaseColor'

type ParticipantInHub = {
  name: string;
  participant_id: string;
  image: {
    url: string;
  };
  last_activity_at?: string;
}

type OaseInHub = {
  oase_id: string;
  oase_name: string;
  participants: ParticipantInHub[];
}

const OaseCardInner = memo(({ data, placeholder, readyCallback, size }: { readyCallback: () => void, data?: any, placeholder?: boolean, size: number }) => {
  const oase: OaseInHub = data as OaseInHub;
  const { t } = useTranslation();
  const { unreadCount } = useNotification(oase?.oase_id ? `${oase?.oase_id}` : "");
  const [icon, nameWithoutEmoji, isEmoji] = useMemo(() => iconAndName(oase?.oase_name || t('hub.oase_card.placeholder')), [oase?.oase_name]);
  const name = useMemo(() => nameWithoutEmoji || t('hub.oase_card.placeholder'), [nameWithoutEmoji]);
  const badgeColor = useMemo(() => `fill-brand-primary`, []);
  const badgeText = useMemo(() => `text-white`, []);

  useEffect(() => {
    readyCallback();
  }, []);

  const sortedParticipants = useMemo(() => {
    return [...oase?.participants || []].sort((a, b) => {
      return (b.last_activity_at || "0").localeCompare(a.last_activity_at || "0");
    });
  }, [oase?.participants]);
  const participantsShowCount = useMemo(() => 5, []);
  const participants: ParticipantInHub[] | undefined = useMemo(() => {
    return sortedParticipants?.slice(0, participantsShowCount);
  }, [sortedParticipants]);
  const hasMoreParticipants = useMemo(() => !!(oase?.participants && (oase?.participants?.length > participantsShowCount)), [oase?.participants]);

  const timeToShow = useMemo(() => {
    if (!sortedParticipants || sortedParticipants.length == 0) {
      return "";
    }

    return sortedParticipants[0]?.last_activity_at;
  }, [sortedParticipants]);
  const relativeTime = relativeWithToday(timeToShow);

  // Vars for positioning participants
  const avatarClasses = useMemo(() => "w-8 h-8", []);
  const horisontalSkydning = useMemo(() => hasMoreParticipants ? 21 : 23.5, [hasMoreParticipants]);
  const verticalSkydning = useMemo(() => hasMoreParticipants ? 17 : 20, [hasMoreParticipants]);
  const leftPadding = useMemo(() => 25, []);
  const bottomPadding = useMemo(() => hasMoreParticipants ? 22 : 18, [hasMoreParticipants]);
  const numberPadding = useMemo(() => -3, []);

  return (
    <>
      <View style={{ width: size, height: size }} className="flex flex-col justify-start items-center">
        {placeholder ? null : <View className="flex w-full flex-col justify-center items-start pl-6 pr-5 pt-9">
          <View className="flex flex-row android:items-end ios:items-center web:items-center gap-1">
            {isEmoji ? (
              <Text className="text-sm opacity-70">{icon}</Text>
            ) : null}
            <FullText numberOfLines={1} ellipsizeMode="tail" className={"text-brand-black-400 web:w-36 text-xs font-t-medium"}>
              {relativeTime.charAt(0).toUpperCase() + relativeTime.slice(1)}
            </FullText>
          </View>
          <FullText numberOfLines={2} ellipsizeMode="tail" className={`text-brand-black-900 web:w-36 text-[17px] web:leading-tight leading-5 tracking-tight font-t-semi pt-1`}>{name}</FullText>
        </View>}

        <View style={{ width: size }} className="grow opacity-90">

          {participants?.map((participant: any, index: number) => {
            return (index % 2 == 1) ? null : (
              <View className="absolute"
                key={index}
                style={{ bottom: bottomPadding + verticalSkydning * ((index + 1) % 2), left: leftPadding + horisontalSkydning * index }}
              >
                <ParticipantAvatar className={avatarClasses} key={index} participant={participant} />
              </View>
            );
          })}
          {participants?.map((participant: any, index: number) => {
            return (index % 2 == 0) ? null : (
              <View className="absolute"
                key={index}
                style={{ bottom: bottomPadding + verticalSkydning * ((index + 1) % 2), left: leftPadding + horisontalSkydning * index }}
              >
                <ParticipantAvatar className={avatarClasses} key={index} participant={participant} />
              </View>
            );
          })}
          {hasMoreParticipants && oase?.participants ? (
            <View className="absolute h-6 flex items-center justify-center"
              style={{ bottom: bottomPadding + numberPadding, left: leftPadding + horisontalSkydning * participantsShowCount }}
            >
              <Text className="pl-1 text-brand-black-400 font-t-bold text-xs text-center leading-none">+{oase?.participants?.length - participantsShowCount}</Text>
            </View>
          ) : null}

        </View>
      </View>
      <View
        className="absolute top-3 -right-[2px]"
      >
        {placeholder || unreadCount == 0 ? null : (
          <SuperEllipse
            className="w-7 h-7 flex items-center justify-center"
            fill={badgeColor} >
            <Text className={`${badgeText} font-t-bold text-sm text-center leading-none`}>{unreadCount}</Text>
          </SuperEllipse>

        )}
      </View>
    </>
  );
});

const OaseCard = ((props: any) => {
  const { index, data, direction, placeholder, show, animate, size } = props
  const ourDelay = useMemo(() => animate ? (Platform.OS == "web" ? index * 20 : index * 120) : 0, [animate, index]);
  const [doneWaiting, setDoneWaiting] = useState(false);
  const [ready, setReady] = useState(Platform.OS == "android" || false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const { push } = useRouter();
  const path = useMemo(() => data?.oase_id ? `/oase/${data?.oase_id}` : "", [data?.oase_id]);

  const textColor = useMemo(() => themeColor("brand", "primary", "text", "light"), []);

  const textAnimation = useDynamicAnimation(() => {
    return {
      scale: 1,
      opacity: 0,
    }
  })

  const pickerAnimation = useDynamicAnimation(() => {
    return {
      scale: 0,
      opacity: 0,
    }
  })

  const activeIndexGivenTouch = useCallback((touch: undefined | { x: number, y: number }): -1 | 0 | 1 | 2 | 3 => {
    if (!touch) return -1;

    if (touch.x > 0 && touch.x < size && touch.y > 0 && touch.y < size) {
      if (touch.x > size / 2) {
        if (touch.y > size / 2) {
          // Bottom right
          return 3;
        } else {
          // Top right
          return 1;
        }
      } else {
        if (touch.y > size / 2) {
          // Bottom left
          return 2;
        } else {
          // Top left
          return 0;
        }
      }
    }

    return -1;
  }, [size]);

  const composed = useMemo(() => {
    const pan = Gesture.Pan().
      activateAfterLongPress(300).
      shouldCancelWhenOutside(false).
      runOnJS(true).
      onStart(() => {
        setTimeout(() => {
          textAnimation.animateTo({
            scale: 0,
            opacity: 0,
          });
        }, 100);
        pickerAnimation.animateTo({
          scale: 1,
          opacity: 1,
        });
      }).
      onFinalize((_, _success) => {
        textAnimation.animateTo({
          scale: 1,
          opacity: 1,
        });
        pickerAnimation.animateTo({
          scale: 0,
          opacity: 0,
        });
      }).
      onTouchesMove((event) => {
        const touch = event.changedTouches[0];
        setActiveIndex(activeIndexGivenTouch(touch));
      }).
      onEnd((event) => {
        switch (activeIndexGivenTouch(event)) {
          case 0:
            push(`${path}?shouldOpen=camera`);
            break;
          case 1:
            push(`${path}?shouldOpen=gallery`);
            break;
          case 2:
            push(`${path}?shouldOpen=gif`);
            break;
          case 3:
            push(`${path}?shouldOpen=text`);
            break;
        }
      });

    const tapUI = Gesture.Tap().
      runOnJS(true).
      onBegin(() => {
        textAnimation.animateTo({
          scale: 0.98,
          opacity: 0.5,
        })
      }).
      onTouchesCancelled(() => {
        textAnimation.animateTo({
          scale: 1,
          opacity: 1,
        })
      });

    const tapNav = Gesture.Tap().
      runOnJS(true).
      onEnd(() => {
        push(path)
        textAnimation.animateTo({
          scale: 1,
          opacity: 1,
        })
      });

    const tap = Gesture.Simultaneous(tapUI, tapNav);

    return Gesture.Race(pan, tap);
  }, [activeIndexGivenTouch, path, pickerAnimation, push, textAnimation]);

  useEffect(() => {
    if (!show) return;

    const timeout = setTimeout(() => {
      setDoneWaiting(true);
    }, ourDelay);
    return () => clearTimeout(timeout);
  }, [show]);

  const pickerClasses = useMemo(() => classNames(textColor, "w-6 h-6"), [textColor]);

  const readyCallback = useCallback(() => {
    setReady(true);
  }, [setReady]);

  const afterBubbleAnimation = useCallback(() => {
    setTimeout(() => {
      textAnimation.animateTo({
        scale: 1,
        opacity: 1,
      });
    }, 0);
  }, [textAnimation]);

  return (
    <View key={data?.oase_id}>
      <GestureDetector gesture={composed}>
        <View key={data?.oase_id} className="cursor-pointer">
          {doneWaiting ? (
            <View
              className={`absolute top-0 left-0 ${placeholder && "opacity-50"}`}
            >
              {ready ? <OaseBubble animate={animate} done={afterBubbleAnimation} size={size} direction={direction} /> : null}
            </View>
          ) : null}
          <MotiView
            transition={{
              type: 'timing',
              duration: 300,
            }}
            state={textAnimation}
          >
            <OaseCardInner
              data={data}
              placeholder={props.placeholder}
              readyCallback={readyCallback}
              size={size}
            />
          </MotiView>
          <View
            className={`absolute top-0 left-0 p-[10%]`}
            style={{
              width: size,
              height: size,
            }}
          >
            <MotiView
              transition={{
                type: 'timing',
                duration: 300,
              }}
              style={{
                flex: 1,
              }}
              state={pickerAnimation}
            >
              <View className="flex-1 flex flex-col items-center justify-around">
                <View className="w-full flex flex-row items-center justify-around">
                  <FontAwesomeIcon icon={[(activeIndex == 0 ? "fas" : "fal"), "camera"]} className={pickerClasses} />
                  <FontAwesomeIcon icon={[activeIndex == 1 ? "fas" : "fal", "images"]} className={pickerClasses} />
                </View>
                <View className="w-full flex flex-row items-center justify-around">
                  <FontAwesomeIcon icon={[activeIndex == 2 ? "fas" : "fal", "gif"]} className={pickerClasses} />
                  <View>
                    <Text className={classNames(pickerClasses, "text-base", activeIndex == 3 ? "font-t-black" : "font-t-regular")}>
                      Aa
                    </Text>
                  </View>
                </View>
              </View>
            </MotiView>
          </View>
        </View>
      </GestureDetector>
    </View>
  );
});

export default memo(OaseCard);
